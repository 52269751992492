import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";
import toast from 'react-hot-toast';

export const Newsletter = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status])

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    const formDetails = { email };
    const fetchEmail = async () => {
      try {
        const response = await fetch('/newsletter', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Adjust the content type based on your API requirements
          },
          body: JSON.stringify(formDetails), // Assuming formDetails contains the data you want to send
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const apiData = await response.json();
        if(apiData.status){
          await sleep(2000);
          return apiData
        }else{
          await sleep(2000);
          throw new Error('Error occurred while fetching email.');
        }
      } catch (error) {
        await sleep(2000);
        throw error;
      }
    };
    
    toast.promise(fetchEmail(), {
      loading: 'Loading',
      success: (data) => `Successfully Added !`,
      error: 'Already Registered !',
    });
    clearFields()
  }

  const clearFields = () => {
    setEmail('');
  }

  return (
      <Col lg={12}>
        <div className="newsletter-bx wow slideInUp">
          <Row>
            <Col lg={12} md={6} xl={5}>
              <h3>Subscribe to our Newsletter<br></br> & Never miss latest updates</h3>
              {status === 'sending' && <Alert>Sending...</Alert>}
              {status === 'error' && <Alert variant="danger">{message}</Alert>}
              {status === 'success' && <Alert variant="success">{message}</Alert>}
            </Col>
            <Col md={6} xl={7}>
              <form onSubmit={handleSubmit}>
                <div className="new-email-bx">
                  <input value={email} type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" />
                  <button type="submit">Submit</button>
                </div>
              </form>
            </Col>
          </Row>
        </div>
      </Col>
  )
}
