import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Typography from '@mui/joy/Typography';
import Check from '@mui/icons-material/Check';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

export function ElitePricingCard() {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 300px), 1fr))',
        gap: 2,
      }}
    >
      <Card
        size="lg"
        variant="solid"
        color="neutral"
        invertedColors
        sx={{ bgcolor: 'neutral.900' }}
        style={{ width : "100%" }}
      >
        <Chip size="sm" variant="outlined" style={{fontSize : "18px"}}>
          ELITE 😎✨
        </Chip>
        <Typography level="h2">Be a Elite Member get lifetime Access.</Typography>
        <Typography level="p" style={{
          display : "flex",
          justifyContent : "center",
          alignItems : "center",
          fontSize : "18px"
        }}>You can give your old laptop/cpu/computers to us, we make them cloud so you can use them as a server for free.</Typography>
        <Divider inset="none" />
        <List
          size="sm"
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            mx: 'calc(-1 * var(--ListItem-paddingX))',
          }}
        >
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            Fully Access Anywhere
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            24hr High Speed Internet
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            24x7 High Security Monitoring
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            50% of your system resources
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            Cancel Anytime
          </ListItem>
        </List>
        <Divider inset="none" />
        <CardActions>
          <Typography level="title-lg" sx={{ mr: 'auto' }} style={{color : "green"}}>
          ₹ 0 {' '}
            <Typography fontSize="sm" textColor="text.tertiary">
              / month
            </Typography>
          </Typography>
          <Button endDecorator={<KeyboardArrowRight />}>
          <a href="/plans?redirect?=elite" style={{
              textDecoration : "none",
              color : "violet",
              display : "inline-block"
            }}>
              Start now
            </a>
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
