import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { Box, Grid } from '@mui/material';

import { PricingCard } from "./cards/PricingCard"

import { ElitePricingCard } from "./cards/ElitePricingCard"

import axios from 'axios';
import { useEffect, useState } from "react";

export const Projects = () => {

  const [dataplan , setDataPlan] = useState([])

  const fetchServices = async()=>{
    const data = await axios({
      method: 'get',
      url : `${process.env.REACT_APP_API_URL}/api/cloud-computing-products`
    })
    setDataPlan(data.data.data)
  }

  useEffect(()=>{
    fetchServices();
  },[])
  // fetchServices()
  // const dataplan = [
  //   {
  //     plan: "LEARNING",
  //     planName: "Happy Learning",
  //     ram: "250 mb",
  //     storage: "1 GB",
  //     description: "Design & Development",
  //     availability : '24 hr',
  //     price : '99',
  //     url : "/plans?redirect=happy-learning"
  //   },
  //   {
  //     plan: "BASIC",
  //     planName: "Silver Warlock",
  //     ram: "512 mb",
  //     storage: "10 GB",
  //     description: "Design & Development",
  //     availability : '24 hr',
  //     price : '149',
  //     url : "/plans?redirect=basic"
  //   },
  //   {
  //     plan: "INTERMEDIATE",
  //     planName: "Gold Warlock",
  //     ram: "1 GB",
  //     storage: "50 GB",
  //     description: "Design & Development",
  //     availability : '24 hr',
  //     price : '299',
  //     url : "/plans?redirect=intermediate"
  //   },
  //   {
  //     plan: "PlATINUM",
  //     planName: "PLATINUM WARLOCK",
  //     ram: "2 GB",
  //     storage: "100 GB",
  //     description: "Design & Development",
  //     availability : '24 hr',
  //     price : '499',
  //     url : "/plans?redirect=platinum"
  //   },
    
  // ];





  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Pricing</h2>
                <p>Discover transparent and flexible pricing at webJaadugar. Tailor-made plans to fit your needs, ensuring cost-effectiveness without compromising on quality. Unlock the full potential of our services with straightforward pricing designed for your business success.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Cloud Computing</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Websites</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Android Apps</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                    <Box>
                      <Grid container spacing={2}>
                        {dataplan.map((data, index) => (
                          <Grid item xs={12} sm={6} md={4} key={index}>
                            <PricingCard data={data.attributes} />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <ElitePricingCard/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="section">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
