// import meter1 from "../assets/img/meter1.svg";
// import meter2 from "../assets/img/meter2.svg";
// import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"
import axios from 'axios';

// import services from "../../data/services.json"

import { useEffect , useState} from 'react';

// ... (imports)

export const Skills = () => {
  const [services, setServices] = useState([]);

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
  };

  const fetchServices = async()=>{
    const data = await axios({
      method: 'get',
      url : `${process.env.REACT_APP_API_URL}/api/services?populate=*`
    })
    setServices(data.data.data)
  }

  useEffect(()=>{
    fetchServices();
  },[])

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Services</h2>
              <p>At webJaadugar, we are dedicated to shaping your digital dreams into reality. As a leading provider of comprehensive IT solutions, we offer a range of services designed to elevate your online presence and propel your business to new heights.</p>
              {services && services.length > 0 ? (
                <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                  {services.map((service, index) => (
                    <div className="item service-cont" key={index} style={{ cursor : "pointer" }}>
                      <img style={{borderRadius : "100px"}} src={`${process.env.REACT_APP_API_URL}${service.attributes.image.data.attributes.formats.large.url}`} alt="Image" />
                      <h5>{service.attributes.title}</h5>
                      <p style={{
                        fontSize : "12px" ,
                        width : "100%",
                        padding : "10px",
                      }} >{service.attributes.desc}</p>
                    </div>
                  ))}
                </Carousel>
              ) : (
                <p>Loading services...</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  );
};

