import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { Toaster } from 'react-hot-toast';


function App() {
  return (
    <>
      <div>
          <Toaster
            position='bottom-center'
            toastOptions={{
              success : {
                theme : {
                  primary : "#4aed88",
                }
              }
            }}
          >
          </Toaster>
        </div>
      <div className="App">
        <NavBar />
        <Banner />
        <Skills />
        <Projects />
        <Contact />
        <Footer />
      </div>
    </>
  );
}

export default App;
